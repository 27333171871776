import useAxios from './basic/use-axios';

export const useGetClientNames = () =>
  useAxios(
    {
      url: '/admin/client/names',
      method: 'GET',
    },
    {manual: true},
  );

export const useGetClientCountries = () => useAxios({
  url: '/admin/client/countries',
  method: 'GET',
});

export const useGetClients = () =>
  useAxios({
    url: '/admin/client/list',
    method: 'GET',
  });

export const useGetAllCostReportZip = () =>
  useAxios(
    {
      url: '/admin/client/cost-reports-zip',
      method: 'GET',
    },
    {manual: true},
  );

export const useGetCostReports = () =>
  useAxios(
    {
      url: '/admin/client/cost-reports',
      method: 'GET',
    },
    {manual: true},
  );

export const useGetAllClientInvoiceZip = () =>
  useAxios(
    {
      url: '/admin/client/invoices-zip',
      method: 'GET',
    },
    {manual: true},
  );

export const useGetClientInvoices = () =>
  useAxios(
    {
      url: '/admin/client/invoices',
      method: 'GET',
    },
    {manual: true},
  );

export const useGetClient = (clientId: string) =>
  useAxios(
    {
      url: `/admin/client/${clientId}`,
      method: 'GET',
    },
  );

export const useUpdateClient = (clientId: string) =>
  useAxios(
    {
      url: `/admin/client/${clientId}`,
      method: 'PUT',
    },
    {manual: true},
  );

export const useGetClientUserEmails = (clientId: string) =>
  useAxios(
    {
      url: `/admin/client/${clientId}/user-emails`,
      method: 'GET',
    },
  );

export const useActivateClient = (clientId: string) =>
  useAxios(
    {
      url: `/admin/client/${clientId}/activate`,
      method: 'POST',
    },
    {manual: true},
  );

export const useRegenerateClientCostReport = () =>
  useAxios(
    {
      url: `/admin/client/regenerate/cost-report`,
      method: 'POST',
    },
    {manual: true},
  );

export const useRegenerateClientInvoice = () =>
  useAxios(
    {
      url: `/admin/client/regenerate/invoice`,
      method: 'POST',
    },
    {manual: true},
  );

