import {Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay} from '@chakra-ui/react';

export const ConfirmDocumentRegenerationModal = ({
  handleProceed,
  loading,
  isOpen,
  onClose,
}: {

    handleProceed?: ()=> void;
    loading?: boolean;
    isOpen?: boolean;
    onClose?: () => void
  }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure you want to issue the document again?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          This may take a while, you can close the window.
        </ModalBody>
        <ModalFooter>
          <Button variant='photonMedium' mr={3} onClick={onClose}>
          Cancel
          </Button>
          <Button variant='photonDark' isLoading={loading} w='auto' onClick={handleProceed}>
          Proceed
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal> );
};
