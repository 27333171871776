import {
  Box,
  Button,
  Flex,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  useGetEditorCerts,
  useGetAllEditorCerts,
  useGetAllEditorCertCSV,
  useRegenerateEditorCert,
} from 'api/editors';
import moment from 'moment';

import {useCallback, useEffect, useState} from 'react';
import BillingPeriodSelector from 'views/dashboard/components/BillingPeriodSelector';
import YearSelector from 'views/dashboard/components/YearSelector';
import {DownloadIcon} from '@chakra-ui/icons';
import {currencyFormatter} from 'utils/number-formatter';
import {downloadFile} from 'utils/download-file';
import Card from 'components/card/Card';
import {useLocation, useNavigate} from 'react-router-dom';
import useCountryList from 'hooks/useCountryList';
import Bugsnag from '@bugsnag/js';
import {ConfirmDocumentRegenerationModal} from 'components/ConfirmDocumentRegenerationModal';
import {FaRedo} from 'react-icons/fa';
import {CustomSpinner} from 'views/finances/client/components/Invoices';


export default function EditorCoc() {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const [year, setYear] = useState(urlParams.get('year') || moment().format('YYYY'));
  const [period, setPeriod] = useState(urlParams.get('period') || '');
  const {getCountryName} = useCountryList();
  const [currentCert, setCurrentCert] = useState<{docId: string, editorId: string}>();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();


  const [{data: editorCertsData}, getEditorCerts] = useGetEditorCerts();
  const [, getAllEditorCerts] = useGetAllEditorCerts();
  const [, getAllEditorCertsCSV] = useGetAllEditorCertCSV();

  const [{loading: regenerateLoading, error}, regenerateDoc] = useRegenerateEditorCert();

  const handleRegenerate = async ()=>{
    try {
      await regenerateDoc({data: {
        editorId: currentCert.editorId,
        docId: currentCert.docId,
      }});
      toast({
        title: 'Document regenerated succesfully',
        status: 'success',
        duration: 3000,
      });
      onClose();
      callGetEditorCerts(year, period);
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  const callGetEditorCerts = useCallback(async (year: string, period: string) => {
    if (year && period) {
      try {
        await getEditorCerts({
          params: {
            year,
            period,
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const callGetAllEditorCerts = useCallback(async (year: string, period: string) => {
    let result;
    if (year && period) {
      try {
        result = await getAllEditorCerts({
          params: {
            year,
            period,
          },
          responseType: 'arraybuffer',
        });
        if (result && result.data) {
          downloadFile(
            result.data,
            `certificate_of_completion${year}_${period}.zip`,
            'application/zip',
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const callGetAllEditorCertsCSV = useCallback(async (year: string, period: string) => {
    let result: any;
    if (year && period) {
      try {
        result = await getAllEditorCertsCSV({
          params: {
            year,
            period,
          },
          responseType: 'arraybuffer',
        });
        if (result && result.data) {
          downloadFile(
            result.data,
            `certificate_of_completion${year}_${period}.csv`,
            'application/octet-stream',
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  useEffect(
    () => {
      callGetEditorCerts(year, period);
    },
    [year, period],
  );

  useEffect(
    () => {
      if (error) {
        toast({
          title: 'Failed to regenerate document',
          status: 'error',
          duration: 3000,
        });
      }
    },
    [error],
  );

  const isRowRegenerating =(docId: string)=> {
    return regenerateLoading && currentCert.docId === docId;
  };

  useEffect(() => {
    const updatedUrl = `${location.pathname}?year=${year}&period=${period}`;
    navigate(updatedUrl);
  }, [year, period]);

  const isButtonDisabled
    = !year || !period || !editorCertsData || editorCertsData?.length === 0;

  return (
    <Card>
      <Text fontSize='18px' fontWeight='bold' m='10px'>
        Certificate of completion
      </Text>
      <Flex justifyContent='space-between'>
        <Flex>
          <YearSelector year={year} setYear={setYear} />
          <BillingPeriodSelector year={year} billingPeriod={period} setBillingPeriod={setPeriod} />
        </Flex>
        <Flex>
          <Button
            variant='photonMedium'
            onClick={() => callGetAllEditorCertsCSV(year, period)}
            m='5px 10px'
            isDisabled={isButtonDisabled}
            w='130px'
          >
            Export
          </Button>
          <Button
            variant='photonLight'
            onClick={() => callGetAllEditorCerts(year, period)}
            mt='5px'
            isDisabled={isButtonDisabled}
          >
            Download all
          </Button>
        </Flex>
      </Flex>
      <Flex justifyContent='center'>
        {editorCertsData?.length > 0 ? (
          <TableContainer w='100%' mt='50px'>
            <Table variant='simple'>
              <Thead>
                <Tr borderBottom={`solid #E2E8F0`}>
                  <Th>Editor</Th>
                  <Th textAlign='center'>Country</Th>
                  <Th textAlign='center'>Billing period</Th>
                  <Th textAlign='center'>HUF</Th>
                  <Th textAlign='center'>EUR</Th>
                </Tr>
              </Thead>
              <Tbody>
                {editorCertsData.map((row: any, i: string) => (
                  <Tr key={i}>
                    <Td>{row.editor}</Td>
                    <Td textAlign='center'>{getCountryName(row.country)}</Td>
                    <Td textAlign='center'>{row.date}</Td>
                    <Td>
                      {row?.certs?.HUF && (
                        <Flex justifyContent='center'>
                          <Text mr='10px'>
                            {row.certs.HUF.netValue
                              && `${currencyFormatter(row.certs.HUF.netValue, 'HUF')} HUF`}
                          </Text>
                          {isRowRegenerating(row.certs.HUF.docId)
                            ?<CustomSpinner/>
                            : <>
                              <Link isExternal target='_blank' href={row.certs.HUF.url}>
                                <DownloadIcon />
                              </Link>
                              {
                                row.certs.HUF.docId && <Box mt='3px' ml='10px'>
                                  <FaRedo
                                    cursor='pointer'
                                    onClick={()=> {
                                      setCurrentCert(row.certs.HUF);
                                      onOpen();
                                    }}/>
                                </Box>
                              }
                            </>
                          }
                        </Flex>
                      )}
                    </Td>
                    <Td>
                      {row?.certs?.EUR && (
                        <Flex justifyContent='center'>
                          <Text mr='10px'>
                            {row.certs.EUR.netValue
                              && `${currencyFormatter(row.certs.EUR.netValue, 'EUR')} EUR`}
                          </Text>
                          {isRowRegenerating(row.certs.EUR.docId)
                            ?<CustomSpinner/>
                            : <>
                              <Link isExternal target='_blank' href={row.certs.EUR.url}>
                                <DownloadIcon />
                              </Link>
                              {
                                row.certs.EUR.docId && <Box mt='3px' ml='10px'>
                                  <FaRedo
                                    cursor='pointer'
                                    onClick={()=> {
                                      setCurrentCert(row.certs.EUR);
                                      onOpen();
                                    }}/>
                                </Box>
                              }
                            </>
                          }
                        </Flex>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <ConfirmDocumentRegenerationModal
              isOpen={isOpen}
              onClose={onClose}
              loading={regenerateLoading}
              handleProceed={handleRegenerate}
            />
          </TableContainer>
        ) : (
          <Text textAlign={'center'} mt='50px'>
            There is no document available for this period.
          </Text>
        )}
      </Flex>
    </Card>
  );
}
