import React, {useEffect} from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import {useManageCreative} from 'api/staff-api';
import {
  Judgement,
  RESTART,
} from '../constants';
import {RefetchCreativesDataType} from '../types';


interface NoShowRestartConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  creativeId: string;
  callback: RefetchCreativesDataType;
  status?: string;
}

const NoShowRestartConfirmationModal: React.FC<NoShowRestartConfirmationModalProps> = ({
  creativeId,
  isOpen,
  onClose,
  callback,
}) => {
  const toast = useToast();

  const [{loading, error}, manageCreative] = useManageCreative();

  useEffect(
    () => {
      if (error) {
        toast({
          title: 'Error during updating hiring status',
          status: 'error',
          duration: 3000,
        });
      }
    },
    [error],
  );

  const setNoShow = async () => {
    const res = await manageCreative({
      data: {judgement: Judgement.NO_SHOW},
      params: {creativeId},
    });
    toast({
      title: res.data.message,
      status: 'success',
      duration: 3000,
    });
    callback();
    onClose();
  };

  return <Modal
    isOpen={isOpen}
    onClose={onClose}
  >
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>Confirmation</ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        <Text>{RESTART}</Text>
      </ModalBody>
      <ModalFooter>
        <Button mr='5px' w='140px' variant='photonMedium' onClick={onClose}>
          Cancel
        </Button>
        <Button
          w='140px'
          variant='photonDark'
          onClick={setNoShow}
          isLoading={loading}
        >
          Yes, proceed
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>;
};

export default NoShowRestartConfirmationModal;
