import React, {useEffect, useState} from 'react';

import {
  Box,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import {AiOutlineDelete} from 'react-icons/ai';

import DevelopmentTable from '../../../components/DevelopmentTable';

import {
  useDeleteMatchCityRecord,
  useGetUnmatchedCities,
  useMatchCity,
} from '../../../api/cities';
import {AsyncSelect} from 'chakra-react-select';
import {useLoadCityOptionsWithDebounce} from '../../creative/helpers/getCities';
import {Cell} from 'react-table';
import {CityInfo} from '../../creative/types';


export default function UnmatchedCitiesList() {
  const loadCityOptionsWithDebounce = useLoadCityOptionsWithDebounce();
  const [
    {
      data: unmatchedCities,
      loading: getUnmatchedCitiesLoading,
      error: getUnmatchedCitiesError,
    },
    reloadUnmatchedCities,
  ] = useGetUnmatchedCities();
  const [deletedRowId, setDeletedRowId] = useState('');
  const [{error: matchCityError}, callMatchCity] = useMatchCity();
  const [{error: matchCityRecordDeleteError}, callMatchCityRecordDelete] = useDeleteMatchCityRecord(deletedRowId);
  const toast = useToast();

  useEffect(
    () => {
      if (matchCityError) {
        toast({
          title: 'Error saving city represents.',
          status: 'error',
          isClosable: true,
        });
      }
      if (getUnmatchedCitiesError) {
        toast({
          title: 'Error getting unknown cities.',
          status: 'error',
          isClosable: true,
        });
      }
      if (matchCityRecordDeleteError) {
        toast({
          title: 'Error delete unknown city record.',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [matchCityError, getUnmatchedCitiesError, matchCityRecordDeleteError],
  );

  useEffect(
    () => {
      if (deletedRowId) {
        callMatchCityRecordDelete()
          .then(
            () => {
              toast(
                {
                  title: 'Successfully delete city matching record.',
                  status: 'success',
                  isClosable: true,
                },
              );
              return reloadUnmatchedCities();
            },
          );
      }
    },
    [callMatchCityRecordDelete],
  );

  const columnsDataUnmatchedCities = {
    city: {
      columnDef: {
        Header: 'City',
        accessor: 'city',
      },
    },
    country: {
      columnDef: {
        Header: 'Country',
        accessor: 'country',
      },
    },
    cityRepresents: {
      columnDef: {
        Header: 'City represents',
        accessor: 'cityRepresents',
        width: 500,
      },
      // eslint-disable-next-line
      renderer: (cell: any) => (
        <AsyncSelect
          name='workingArea'
          placeholder='Select working area'
          loadOptions={loadCityOptionsWithDebounce}
          onChange={
            async ({value}: {value: CityInfo}) => {
              await callMatchCity(
                {
                  data: {
                    matchId: cell.row.original._id,
                    cityRepresents: value.name,
                  },
                },
              );
              toast({
                title: 'Successfully saved city matching.',
                status: 'success',
                isClosable: true,
              });
              await reloadUnmatchedCities();
            }
          }
          chakraStyles={{
            // eslint-disable-next-line
            container: (provided: any) => ({
              ...provided,
              width: {base: '90vw', md: '500px'},
              bg: 'white',
            }),
            // eslint-disable-next-line
            placeholder: (provided: any) => ({
              ...provided,
              px: '14px',
              color: 'black',
            }),
          }}
        />
      ),
    },
    _id: {
      columnDef: {
        Header: 'Actions',
        accessor: '_id',
      },
      // eslint-disable-next-line
      renderer: (cell: Cell<any, any>) => {
        const id = cell.value;
        return <IconButton
          onClick={() => setDeletedRowId(id)}
          colorScheme='photonOffWhite'
          color='photonDeepBlue'
          aria-label='Delete record'
          icon={<AiOutlineDelete />}
        />;
      },
    },
  };

  return <Box pt={{base: '50px', md: '80px', xl: '130px'}} h="90vh" display='flex'>
    {getUnmatchedCitiesError && <div>Error during getting data: {getUnmatchedCitiesError.message}</div>}
    <DevelopmentTable
      variant='underlineRows'
      label='Unresolved city matches'
      // eslint-disable-next-line
      columnsData={Object.values(columnsDataUnmatchedCities).map((column: any) => column.columnDef)}
      renderersData={columnsDataUnmatchedCities}
      tableData={unmatchedCities}
      total={unmatchedCities?.length}
      isLoading={getUnmatchedCitiesLoading}
    />
  </Box>;
};
