import React, {FC, useEffect, useMemo, useState} from 'react';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
  Textarea,
  useClipboard,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {AsyncSelect, Select} from 'chakra-react-select';
import {Control, Controller, useForm, UseFormSetValue} from 'react-hook-form';
import {LuCopy, LuCopyCheck} from 'react-icons/lu';
import {MdDelete, MdModeEdit, MdOutlineCancel} from 'react-icons/md';
import {FiArrowRightCircle} from 'react-icons/fi';
import {FaRegSave} from 'react-icons/fa';
import {useParams} from 'react-router-dom';
import moment from 'moment-timezone';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import {useDeleteAdminComment, useUpdateProject} from '../../../api/projects';
import {CustomFieldStyle, datePickerStyles, Option, statusColors} from '../variables/styles';
import {getCityList, useLoadCityOptionsWithDebounce} from '../../creative/helpers/getCities';
import config from '../../../config';
import {CustomFieldPropTypes} from '../types';
import {useFormContext} from 'contexts/useFormContext';
import {filterStateOptions} from '../constants';
import TimeChangeReasonModal from './TimeChangeReasonModal';
import useCountryList from 'hooks/useCountryList';
import calculateDeliveryDeadlines from 'utils/calculate-delivery-deadlines';
import getTimeDifferenceInHours from 'utils/get-time-difference-in-hours';
import {CityInfo} from '../../creative/types';
import Bugsnag from '@bugsnag/js';


const filterOptions = (project: any, states: { label: string; value: string }[]) => {
  return states.filter((state) => {
    if (!project.manualEditingEnabled && state.value === 'editor-post-production') {
      return false;
    }
    return !(!project.backgroundRemovalEnabled && state.value === 'ai-post-production');
  });
};

interface CustomFieldComponentProps {
  value: string;
  isLongText?: boolean;
  // eslint-disable-next-line
  register: any;
  onBlur: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

export const FieldComponent: FC<CustomFieldComponentProps> = ({value, isLongText, register, onBlur}) => {
  return isLongText ? (
    <Textarea
      {...register}
      onBlur={onBlur}
      bg='white'
      defaultValue={value}
      mb={4}
      w={{base: '100%', lg: '400px'}}
      h={(value.length / 40) * 30}
    />
  ) : (
    <Input
      {...register}
      onBlur={onBlur}
      bg='white'
      defaultValue={value}
      mb={4}
      w={{base: '100%', lg: '400px'}}
    />
  );
};

export const CustomField = React.memo(
  (
    {
      label,
      id,
      register,
      getValues,
      setEditField,
      editField,
      errors,
      shouldReset,
      defaultValue,
    } : CustomFieldPropTypes) => {
    const value = getValues(id);
    const isEditedField = id === editField;
    const isLongText = value?.length > 40;

    return (
      <FormControl isInvalid mb='10px'>
        <FormLabel display='flex' justifyContent={'space-between'}>
          {label}
        </FormLabel>

        <Flex mb='0px' h='40px'>
          {
            isEditedField
            && <FieldComponent
              value={value}
              isLongText={isLongText}
              register={register(id)}
              onBlur={() => setEditField('')}
            />
          }
          {
            !isEditedField
            && <>
              <Text {...CustomFieldStyle}>{shouldReset ? defaultValue : value}</Text>
              <Button ml='5px' onClick={() => setEditField(id)}>
                <MdModeEdit size={20}/>
              </Button>
            </>
          }
        </Flex>
        <Text color='red' fontSize='14px'>{ errors && errors[id]?.message}</Text>
      </FormControl>
    );
  },
);


export const CustomDateField = React.memo(
  ({label, id, getValues, control, setValue, setEditField, editField}: Omit<CustomFieldPropTypes, 'register'> & {
    control: Control<any, any>;
    setValue: UseFormSetValue<any>
  }) => { // TODO specify types
    let value = getValues(id);
    const originalValue = value;

    return (
      <FormControl isInvalid>
        <FormLabel display='flex' justifyContent={'space-between'}>
          {label}
        </FormLabel>

        <Flex>
          {editField === id ? (
            <>
              <Controller
                name={id}
                control={control}
                defaultValue={value}
                render={() => (
                  <Box
                    as={DateTimePicker}
                    value={value}
                    onChange={(newValue: Date | null) => {
                      const formattedDate = moment(newValue)
                        .format('YYYY.MM.DD HH:mm');

                      setValue(id, formattedDate, {
                        shouldDirty: true,
                      });
                      value = formattedDate;
                    }}
                    clearIcon={null}
                    calendarIcon={null}
                    disableClock
                    autoFocus
                    isCalendarOpen
                    showLeadingZeros
                    sx={{...datePickerStyles,
                      'w': '345px',
                      'h': '40px',
                      'bg': 'white',
                      'borderRadius': '15px',
                      'pl': '10px',
                      'fontSize': '16px',
                      'border': 'none',
                    }}
                  />
                )}
              />
              <Button ml='5px' onClick={() => setEditField('')}>
                <FaRegSave size={20}/>
              </Button>
              <Button ml='5px' onClick={() => {
                const formattedDate = moment(originalValue)
                  .format('YYYY.MM.DD HH:mm');

                setValue(id, formattedDate, {
                  shouldDirty: false,
                });

                setEditField('');
              }}>
                <MdOutlineCancel size={20}/>
              </Button>
            </>
          ) : (
            <>
              <Text {...CustomFieldStyle}>{value}</Text>
              <Button ml='5px' onClick={() => setEditField(id)}>
                <MdModeEdit size={20}/>
              </Button>
            </>
          )}
        </Flex>
      </FormControl>
    );
  },
);

// eslint-disable-next-line
export default function ProjectBasicData(props: { projectData: any; refetch: any }) {
  const {setIsFormDirty, shouldReset, setShouldReset} = useFormContext();
  const {projectData, refetch} = props;
  const {projectId} = useParams();
  const toast = useToast();
  const {onCopy, hasCopied} = useClipboard(projectData._id);
  const [editField, setEditField] = useState('');
  const [formData, setFormData] = useState({});
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [cities] = useState([]);
  const {getCountryName} = useCountryList();
  const loadCityOptionsWithDebounce = useLoadCityOptionsWithDebounce();

  const {timeDifferenceInHours, ianaCode} = getTimeDifferenceInHours(projectData.ianaCode);

  const defaultValues = useMemo(
    () => ({
      _id: projectData._id,
      status: projectData.status,
      shootingTimeLocal: moment(projectData.shootingTime).tz(ianaCode).format('YYYY.MM.DD HH:mm'),
      photographerDeliveryDeadlineLocal: moment(projectData.photographerDeliveryDeadline)
        .tz(ianaCode)
        .format('YYYY.MM.DD HH:mm'),
      deliveryDeadlineLocal: moment(projectData.deliveryDeadline)
        .tz(ianaCode)
        .format('YYYY.MM.DD HH:mm'),
      locationObj: projectData.locationObj,
      adminComment: projectData.adminComment,
    }),
    [projectData, ianaCode],
  );

  const {
    handleSubmit,
    register,
    formState: {isDirty},
    setValue,
    getValues,
    watch,
    reset,
    control,
  } = useForm({
    defaultValues,
  });

  const watchCity = watch('locationObj.city');
  const watchCountry = watch('locationObj.country');
  const watchStatus = watch('status');
  const shootingTimeLocalValue = watch('shootingTimeLocal');
  const adminComment = watch('adminComment');

  const [{loading: updateProjectInProgress, error: updateProjectError}, updateProject]
    = useUpdateProject(projectId);

  const [{error: deleteCommentError}, deleteAdminComment]
    = useDeleteAdminComment(projectId);

  useEffect(() => {
    if (
      shootingTimeLocalValue
      !== moment(projectData.shootingTime).tz(ianaCode).format('YYYY.MM.DD HH:mm')
    ) {
      adjustTimes(shootingTimeLocalValue);
    }
  }, [shootingTimeLocalValue, projectData.shootingTime, ianaCode]);

  useEffect(() => {
    if (updateProjectError || deleteCommentError) {
      toast({
        title: updateProjectError.response.data.message,
        status: 'error',
      });
    }
  }, [updateProjectError, deleteCommentError]);

  useEffect(() => {
    if (updateProjectError) {
      toast({
        title: updateProjectError.response.data.message,
        status: 'error',
      });
    }
  }, [updateProjectError]);

  useEffect(() => {
    if (hasCopied) {
      toast({
        title: 'Project ID has been copied',
        status: 'success',
        duration: 3000,
      });
    }
  }, [hasCopied, toast]);

  useEffect(() => {
    if (isDirty) {
      setShouldReset(false);
      setIsFormDirty(isDirty);
    }
  }, [isDirty]);

  useEffect(() => {
    if (shouldReset) {
      reset(defaultValues);
    }
  }, [shouldReset]);

  // eslint-disable-next-line
  const handleSaveClick = async (data: any) => {
    if (data.shootingTimeLocal !== defaultValues.shootingTimeLocal) {
      setFormData(data);
      onOpen();
    } else {
      sendProjectData(data);
    }
  };

  // eslint-disable-next-line
  const sendProjectData = async (data: any) => {
    await updateProject({data});
    toast({
      title: 'Project updated',
      status: 'success',
      duration: 3000,
    });
    setIsFormDirty(false);
    refetch();
  };

  const adjustTimes = (shootingTime: string) => {
    const {deliveryDeadline, photographerDeliveryDeadline} = calculateDeliveryDeadlines({
      projectData,
      shootingTime,
      timeDifferenceInHours,
    });

    setValue(
      'deliveryDeadlineLocal',
      moment(deliveryDeadline)
        .tz(ianaCode)
        .format('YYYY.MM.DD HH:mm'),
    );
    setValue(
      'photographerDeliveryDeadlineLocal',
      moment(photographerDeliveryDeadline)
        .tz(ianaCode)
        .format('YYYY.MM.DD HH:mm'),
    );
  };

  const handledeleteAdminComment = async () => {
    setValue('adminComment', null);
    try {
      await deleteAdminComment();
      toast({
        title: 'Project updated',
        status: 'success',
        duration: 3000,
      });
      refetch();
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  return (
    <Box mt='50px'>
      <form onSubmit={handleSubmit(handleSaveClick)}>
        <Flex
          justifyContent={{base: 'center', lg: 'space-around'}}
          alignItems={{base: 'center', lg: 'start'}}
          flexDirection={{base: 'column', lg: 'row'}}
        >
          <Flex direction='column' w={{base: '85vw', lg: '500px'}}>
            <FormLabel>Id</FormLabel>
            <Flex>
              <Flex {...CustomFieldStyle}>
                <Text>{projectData._id}</Text>
              </Flex>
              <Button ml='5px' onClick={onCopy}>
                {hasCopied ? <LuCopyCheck size={25}/> : <LuCopy size={25}/>}
              </Button>
            </Flex>

            <FormControl>
              <FormLabel display='flex' justifyContent={'space-between'}>
                Status
              </FormLabel>

              <Flex>
                {editField === 'status' ? (
                  <Select
                    options={filterOptions(projectData, filterStateOptions)}
                    components={{Option}}
                    placeholder='Select statuses'
                    onChange={({value}) => setValue('status', value)}
                    closeMenuOnSelect={true}
                    value={{value: watchStatus, label: watchStatus}}
                    chakraStyles={{
                      // eslint-disable-next-line
                      container: (provided: any) => ({
                        ...provided,
                        width: '400px',
                        borderColor: '#A3AED0',
                        bg: 'white',
                        zIndex: 3,
                        borderRadius: '5px',
                        mb: '15px',
                      }),
                      // eslint-disable-next-line
                      placeholder: (provided: any) => ({
                        ...provided,
                        color: 'black',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: statusColors[watchStatus].secondary,
                        fontWeight: 'bold',
                        bg: statusColors[watchStatus].primary,
                        borderRadius: '20px',
                        pl: '10px',
                      }),
                    }}
                  />
                ) : (
                  <>
                    <Flex {...CustomFieldStyle}>
                      <Text>{getValues('status')}</Text>
                    </Flex>
                    <Button ml='5px' onClick={() => setEditField('status')}>
                      {<MdModeEdit size={20}/>}
                    </Button>
                  </>
                )}
              </Flex>
            </FormControl>

            <CustomDateField
              label='Shooting time (local time zone)'
              id='shootingTimeLocal'
              getValues={getValues}
              control={control}
              setValue={setValue}
              setEditField={setEditField}
              editField={editField}
              shouldReset={shouldReset}
              defaultValue={defaultValues.shootingTimeLocal}
            />
            <CustomField
              label='Photographer delivery deadline (local time zone)'
              id='photographerDeliveryDeadlineLocal'
              register={register}
              getValues={getValues}
              setEditField={setEditField}
              editField={editField}
              shouldReset={shouldReset}
              defaultValue={defaultValues.photographerDeliveryDeadlineLocal}
            />
            <CustomField
              label='Delivery deadline (local time zone)'
              id='deliveryDeadlineLocal'
              register={register}
              getValues={getValues}
              setEditField={setEditField}
              editField={editField}
              shouldReset={shouldReset}
              defaultValue={defaultValues.deliveryDeadlineLocal}
            />
            <FormLabel>Country </FormLabel>
            <Flex {...CustomFieldStyle}>
              <Text>{getCountryName(watchCountry)}</Text>
            </Flex>
            <CustomField
              label='ZIP code'
              id='locationObj.zipCode'
              register={register}
              getValues={getValues}
              setEditField={setEditField}
              editField={editField}
              shouldReset={shouldReset}
              defaultValue={defaultValues.locationObj.zipCode}
            />

            <FormLabel>Client comment</FormLabel>
            {projectData.comment?.length > 40 ? (
              <Flex {...CustomFieldStyle} height='auto'>
                <Text wordBreak="break-word">{projectData.comment}</Text>
              </Flex>
            ) : (
              <Flex {...CustomFieldStyle}>
                <Text>{projectData.comment}</Text>
              </Flex>
            )}

            <Flex w={{lg: '510px', base: '100%'}}>
              <CustomField
                label='Admin comment'
                id='adminComment'
                register={register}
                getValues={getValues}
                setEditField={setEditField}
                editField={editField}
                shouldReset={shouldReset}
                defaultValue={defaultValues.adminComment}
              />
              {adminComment
              && <Button alignSelf='end' mb='10px' ml='5px'>
                <MdDelete size={20} onClick={handledeleteAdminComment}/>
              </Button>
              }
            </Flex>
          </Flex>

          <Flex direction='column' w={{base: '85vw', lg: '500px'}}>
            <FormLabel> Client name</FormLabel>
            <Flex {...CustomFieldStyle}>
              <Text>{projectData.clientObj.name}</Text>
            </Flex>
            <FormLabel> Package name</FormLabel>
            <Flex {...CustomFieldStyle} justifyContent='space-between'>
              <Text>{projectData.packageObj.name}</Text>
              <Link
                href={`${config.oldAdminUrl}/#/enterprise-packages/${projectData.packageObj.id}/show`}
                target='_blank'
              >
                <Box mr='5px'>
                  <FiArrowRightCircle/>
                </Box>
              </Link>
            </Flex>
            <FormLabel>Shooting time (current time zone) </FormLabel>
            <Flex {...CustomFieldStyle}>
              <Text>
                {moment(getValues('shootingTimeLocal'))
                  .subtract(timeDifferenceInHours, 'h')
                  .format('YYYY.MM.DD HH:mm')}
              </Text>
            </Flex>
            <FormLabel>Photographer delivery deadline (current time zone) </FormLabel>
            <Flex {...CustomFieldStyle}>
              <Text>
                {moment(getValues('photographerDeliveryDeadlineLocal'))
                  .subtract(timeDifferenceInHours, 'h')
                  .format('YYYY.MM.DD HH:mm')}
              </Text>
            </Flex>
            <FormLabel>Delivery deadline (current time zone) </FormLabel>
            <Flex {...CustomFieldStyle}>
              <Text>
                {moment(getValues('deliveryDeadlineLocal'))
                  .subtract(timeDifferenceInHours, 'h')
                  .format('YYYY.MM.DD HH:mm')}
              </Text>
            </Flex>

            <FormControl>
              <FormLabel display='flex' justifyContent={'space-between'}>
                City
              </FormLabel>
              <Flex>
                {editField === 'locationObj.city' ? (
                  <AsyncSelect
                    name='workingArea'
                    placeholder='Select working area'
                    onInputChange={(value: string) => getCityList(value)}
                    options={cities}
                    loadOptions={loadCityOptionsWithDebounce}
                    onChange={({value}: {value: CityInfo}) => {
                      const [city, country] = value.name.split(',').map((item) => item.trim());
                      setValue('locationObj.city', city);
                      setValue('locationObj.country', country);
                      setIsFormDirty(true);
                    }}
                    value={{
                      value: watchCity,
                      label: watchCity,
                    }}
                    chakraStyles={{
                      // eslint-disable-next-line
                      container: (provided: any) => ({
                        ...provided,
                        w: {base: '100%', lg: '400px'},
                        bg: 'white',
                        mb: '15px',
                      }),
                      // eslint-disable-next-line
                      placeholder: (provided: any) => ({
                        ...provided,
                        px: '14px',
                        color: 'black',
                      }),
                    }}
                  />
                ) : (
                  <>
                    <Flex {...CustomFieldStyle}>
                      <Text>{getValues('locationObj.city')}</Text>
                    </Flex>
                    <Button ml='5px' onClick={() => setEditField('locationObj.city')}>
                      {<MdModeEdit size={20}/>}
                    </Button>
                  </>
                )}
              </Flex>
            </FormControl>
            <CustomField
              label='Address'
              id='locationObj.address'
              register={register}
              getValues={getValues}
              setEditField={setEditField}
              editField={editField}
              shouldReset={shouldReset}
              defaultValue={defaultValues.locationObj.address}
            />
          </Flex>
        </Flex>
        <Flex justifyContent='center' m='50px'>
          <Button isLoading={updateProjectInProgress} type='submit' variant='photonDark' w='200px'>
            Save
          </Button>
        </Flex>
      </form>
      <TimeChangeReasonModal
        data={formData}
        isOpen={isOpen}
        onClose={onClose}
        handleSave={sendProjectData}
      />
    </Box>
  );
}
