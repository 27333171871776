import React, {useCallback, useMemo} from 'react';
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import {Cell, Column, useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import Card from './card/Card';

interface RowData {
  bg: string;
}

export default function SimpleTable(props: {
  columnsData: Column<object>[];
  renderersData: Record<string, { renderer?: Function; columnDef: object }>;
  tableData: object[];
  total: number;
  label?: string;
  menuComp?: React.ReactNode;
  variant?: string;
  filterComp?: React.ReactNode;
  isLoading?: boolean;
  titleIcon?: React.ReactNode
}) {
  const {
    columnsData,
    tableData,
    label,
    menuComp,
    renderersData,
    variant,
    filterComp,
    total,
    titleIcon,
  } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const renderers = useMemo(() => renderersData, [renderersData]);
  const data = useMemo(() => tableData || [], [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0, pageSize: 25},
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {getTableProps, getTableBodyProps, headerGroups, page, prepareRow} = tableInstance;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const renderCell = useCallback(
    (cell: Cell) => {
      const renderer = renderers[cell.column.id]?.renderer;

      if (renderer) {
        return renderer(cell);
      } else {
        return (
          <Text color={textColor} fontSize='sm' fontWeight='700'>
            {cell.value}
          </Text>
        );
      }
    },
    [renderers, textColor],
  );

  return (
    <Card
      flexDirection='column'
      flexGrow='1'
      w='100%'
      px='0px'
      overflowX={{sm: 'scroll', lg: 'hidden'}}
    >
      <Flex alignItems='center' mb='20px'>
        <Text
          pl='25px'
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'
        >
          {label}
        </Text>
        {titleIcon}
      </Flex>

      {filterComp}
      <Flex justifyContent='right' mr='20px'>
        {menuComp}
      </Flex>

      <Table {...getTableProps()} variant={variant || 'simple'} color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            const {bg} = row.original as RowData;
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => (
                  <Td
                    {...cell.getCellProps()}
                    key={index}
                    fontSize={{sm: '14px'}}
                    minW={{sm: '150px', md: '200px', lg: 'auto'}}
                    borderColor='transparent'
                    bg={bg}
                  >
                    {renderCell(cell)}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {total < 1 && (
        <Flex w='100%' justifyContent='center'>
          Ther's no data to show
        </Flex>
      )}
    </Card>
  );
}
