import {
  Flex,
  Link,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';

import {useCallback, useEffect, useState} from 'react';
import BillingPeriodSelector from 'views/dashboard/components/BillingPeriodSelector';
import YearSelector from 'views/dashboard/components/YearSelector';
import {DownloadIcon} from '@chakra-ui/icons';
import {currencyFormatter} from 'utils/number-formatter';
import Card from 'components/card/Card';
import {useLocation, useNavigate} from 'react-router-dom';
import {useGetClientInvoices, useRegenerateClientInvoice} from 'api/clients';
import useCountryList from 'hooks/useCountryList';
import Bugsnag from '@bugsnag/js';
import {ConfirmDocumentRegenerationModal} from 'components/ConfirmDocumentRegenerationModal';
import {FaRedo} from 'react-icons/fa';

export const CustomSpinner: React.FC = () => {
  return <Spinner speed="1.5s" size={'sm'} />;
};
export default function Invoices() {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const [year, setYear] = useState(urlParams.get('year') || moment().format('YYYY'));
  const [period, setPeriod] = useState(urlParams.get('period') || '');
  const {getCountryName} = useCountryList();
  const [currentRow, setCurrentRow] = useState<{certs: {EUR: {clientId: string, docId: string}, HUF: {clientId: string, docId: string}}}>();
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [{data: clientInvoiceData}, getClientInvoices, cancelGetInvoices] = useGetClientInvoices();

  const [{loading: regenerateLoading, error}, regenerateDoc] = useRegenerateClientInvoice();
  const toast = useToast();

  const isRowRegenerating =(docId: string, currency: 'EUR' | 'HUF')=> {
    const {certs} = currentRow || {};
    return regenerateLoading && certs[currency].docId === docId;
  };

  const handleRegenerate = async ()=>{
    try {
      await regenerateDoc({data: {
        clientId: currentRow.certs.EUR.clientId || currentRow.certs.HUF.clientId,
        docId: currentRow.certs.EUR.docId || currentRow.certs.HUF.docId,
      }});
      toast({
        title: 'Document regenerated succesfully',
        status: 'success',
        duration: 3000,
      });
      onClose();
      callGetClientInvoices(year, period);
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  const callGetClientInvoices = useCallback(async (year: string, period: string) => {
    if (year && period) {
      try {
        await getClientInvoices({
          params: {
            year,
            period,
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  useEffect(
    () => {
      if (error) {
        toast({
          title: 'Failed to regenerate document',
          status: 'error',
          duration: 3000,
        });
      }
    },
    [error],
  );

  useEffect(
    () => {
      callGetClientInvoices(year, period);
      return cancelGetInvoices;
    },
    [year, period],
  );

  useEffect(() => {
    const updatedUrl = `${location.pathname}?year=${year}&period=${period}`;
    navigate(updatedUrl);
  }, [year, period]);

  return (
    <Card>
      <Text fontSize='18px' fontWeight='bold' m='10px'>
        Invoices
      </Text>
      <Flex justifyContent='space-between'>
        <Flex>
          <YearSelector year={year} setYear={setYear} />
          <BillingPeriodSelector year={year} billingPeriod={period} setBillingPeriod={setPeriod} />
        </Flex>
      </Flex>
      <Flex justifyContent='center'>
        {clientInvoiceData?.length > 0 ? (
          <TableContainer w='100%' mt='50px'>
            <Table variant='simple'>
              <Thead>
                <Tr borderBottom={`solid #E2E8F0`}>
                  <Th>Client</Th>
                  <Th textAlign='center'>Country</Th>
                  <Th textAlign='center'>Billing period</Th>
                  <Th textAlign='center'>Invoice number</Th>
                  <Th textAlign='center'>HUF</Th>
                  <Th textAlign='center'>EUR</Th>
                  <Th textAlign='center'>Regenerate</Th>
                </Tr>
              </Thead>
              <Tbody>
                {clientInvoiceData.map((row: any, i: string) => (
                  <Tr key={i}>
                    <Td>{row.client}</Td>
                    <Td textAlign='center'>{getCountryName(row.country)}</Td>
                    <Td textAlign='center'>{row.date}</Td>
                    <Td textAlign='center'>
                      {row.certs.HUF?.serialNumber || row.certs.EUR?.serialNumber}
                    </Td>
                    <Td>
                      {row?.certs?.HUF && (
                        <Flex justifyContent='center'>
                          <Text mr='10px'>
                            {row.certs.HUF.netSum
                              && `${currencyFormatter(row.certs.HUF.netSum, 'HUF')} HUF`}
                          </Text>
                          {isRowRegenerating(row.certs.HUF.docId, 'HUF')
                            ?<CustomSpinner/>
                            : <Link isExternal target='_blank' href={row.certs.EUR.url}>
                              <DownloadIcon/>
                            </Link>
                          }
                        </Flex>
                      )}
                    </Td>
                    <Td>
                      {row?.certs?.EUR && (
                        <Flex justifyContent='center'>
                          <Text mr='10px'>
                            {row.certs.EUR.netSum
                              && `${currencyFormatter(row.certs.EUR.netSum, 'EUR')} EUR`}
                          </Text>
                          {isRowRegenerating(row.certs.EUR.docId, 'EUR')
                            ?<CustomSpinner/>
                            : <Link isExternal target='_blank' href={row.certs.EUR.url}>
                              <DownloadIcon/>
                            </Link>
                          }
                        </Flex>
                      )}
                    </Td>
                    <Td textAlign='center' display='flex' justifyContent='center'>
                      {isRowRegenerating(row.certs.HUF?.docId || row.certs.EUR?.docId, 'EUR')
                        ? <CustomSpinner/>
                        : <FaRedo
                          cursor='pointer'
                          onClick={()=> {
                            setCurrentRow(row);
                            onOpen();
                          }}/>
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <ConfirmDocumentRegenerationModal
              isOpen={isOpen}
              onClose={onClose}
              loading={regenerateLoading}
              handleProceed={handleRegenerate}
            />
          </TableContainer>
        ) : (
          <Text textAlign={'center'} mt='50px'>
            There is no document available for this period.
          </Text>
        )}
      </Flex>
    </Card>
  );
}
