import React, {useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import {useManageCreative} from 'api/staff-api';
import {
  ACCEPT_A,
  ACCEPT_B,
  ApplicantStatus,
  Judgement,
  rejectOptionLabels,
  RejectTitleOptions,
} from '../constants';
import {RefetchCreativesDataType} from '../types';


interface HiringModalProps {
  isOpen: boolean;
  onClose: (successfulDelete?: boolean) => void;
  judgement: string;
  creativeId: string;
  refetch: RefetchCreativesDataType;
  status?: string;
}

const HiringModal: React.FC<HiringModalProps> = ({
  creativeId,
  judgement,
  isOpen,
  onClose,
  refetch,
  status,
}) => {
  const toast = useToast();

  const [rejectStatus, setRejectStatus] = useState(0);
  const [rejectReasons, setRejectReasons] = useState([]);
  const [, setConfirmValid] = useState(undefined);
  const [, setConfirmName] = useState('');

  const [{loading: manageCreativeLoading}, manageCreative] = useManageCreative();

  useEffect(() => {
    setRejectStatus(0);
  }, [isOpen]);

  const handleManageCreative = async () => {
    try {
      const res = await manageCreative({
        data: {
          judgement,
          reason: rejectReasons.length > 0 ? rejectReasons : null,
        },
        params: {
          creativeId,
        },
      });
      toast({
        title: res.data.message,
        status: 'success',
        duration: 3000,
      });
      refetch();
      onClose();
    } catch {
      toast({
        title: 'Error during updating hiring status',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const deactivate = async () => {
    try {
      const res = await manageCreative({
        data: {
          judgement: 'off',
        },
        params: {
          creativeId,
        },
      });
      toast({
        title: res.data.message,
        status: 'success',
        duration: 3000,
      });
      refetch();
      onClose();
    } catch {
      toast({
        title: 'Error during updating hiring status',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const isRejecting = judgement !== 'accepted';

  return <Modal
    isOpen={isOpen}
    onClose={
      () => {
        setConfirmValid(undefined);
        setConfirmName('');
        onClose();
      }
    }
  >
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>Confirmation</ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        {
          judgement !== Judgement.REJECTED
          && <Text>
            {
              judgement === Judgement.ACCEPTED
              && status === ApplicantStatus.NEW_APPLICANT
              && ACCEPT_A
            }
            {
              judgement === Judgement.ACCEPTED
              && status !== ApplicantStatus.NEW_APPLICANT
              && ACCEPT_B
            }
          </Text>
        }

        {
          judgement === Judgement.REJECTED
          && <>
            <Text fontWeight={rejectStatus === 2 ? 'bold' : ''}>
              {RejectTitleOptions[rejectStatus]}
            </Text>

            {
              rejectStatus === 2
              && <Flex my="5px" direction='column'>
                <CheckboxGroup value={rejectReasons} onChange={(value) => setRejectReasons(value)}>
                  {
                    Object.entries(rejectOptionLabels).map(
                      ([value, label]) => <Checkbox m='5px' value={value} key={value}>
                        {label}
                      </Checkbox>,
                    )
                  }
                </CheckboxGroup>
              </Flex>
            }
          </>
        }
      </ModalBody>
      <ModalFooter>
        {
          !isRejecting || isRejecting && rejectStatus < 2
            ? <>
              {
                rejectStatus === 0
                  ? <Button mr='5px' w='140px' variant='photonMedium' onClick={() => onClose()}>
                    Cancel
                  </Button>
                  : <Button mr='5px' w='140px' variant='photonMedium' onClick={deactivate}>
                    No
                  </Button>
              }
              <Button
                w='140px'
                variant='photonDark'
                onClick={
                  !isRejecting
                    ? handleManageCreative
                    : () => setRejectStatus(rejectStatus + 1)
                }
                isLoading={manageCreativeLoading}
              >
                {
                  rejectStatus === 0
                  && 'Yes, proceed'
                }
                {
                  rejectStatus === 1
                  && 'Allow'
                }
              </Button>
            </>
            : <>
              <Button mr='5px' w='140px' variant='photonMedium' onClick={() => onClose()}>
                Cancel
              </Button>
              <Button
                w='140px'
                variant='photonDark'
                onClick={handleManageCreative}
                isLoading={manageCreativeLoading}
                isDisabled={rejectStatus === 2 && rejectReasons.length === 0}
              >
                Finish
              </Button>
            </>
        }
      </ModalFooter>
    </ModalContent>
  </Modal>;
};

export default HiringModal;
