import {Box, Button, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, useDisclosure, useToast} from '@chakra-ui/react';
import {Controller, FieldErrors, useForm, UseFormRegister} from 'react-hook-form';
import {useUpdateCreative} from '../../../api/staff-api';
import {useParams} from 'react-router-dom';
import {CheckIcon, CloseIcon} from '@chakra-ui/icons';
import React, {useEffect, useState} from 'react';
import {useFormContext} from 'contexts/useFormContext';
import useCountryList from 'hooks/useCountryList';
import {CountryItem} from 'services/country-list';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';
import {CustomFieldStyle, datePickerStyles} from 'views/projects/variables/styles';
import {MdModeEdit} from 'react-icons/md';

export const CustomField = React.memo(
  ({id, label, register, errors}: {id: string, label: string, register: UseFormRegister<any>, errors: FieldErrors, w?:string}) => {
    return (
      <FormControl id={id} mb={4}>
        <FormLabel>{label}</FormLabel>
        <Input variant='main' {...register(id)}/>
        <Text color="red" fontSize="14px">
          {typeof errors[id]?.message === 'string' ? errors[id]?.message : null}
        </Text>
      </FormControl>
    );
  },
);

// eslint-disable-next-line
export default function CreativeBillingData(props: { creativeData: any }) {
  const {setIsFormDirty, shouldReset, setShouldReset} = useFormContext();
  const {creativeData} = props;
  const {creativeId} = useParams();
  const toast = useToast();
  const {countryList} = useCountryList();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [billingAddressObj, setBillingAddressObj] = useState({
    city: creativeData.billingAddressObj?.city,
    zip: creativeData.billingAddressObj?.zip,
    street: creativeData.billingAddressObj?.street,
  });


  const creativeBillingSchema = z.object({
    birthDate: z
      .date(),
    billingName: z
      .string()
      .min(1, 'Billing name is required')
      .max(150, 'Must be less then 150 characters'),
    billingAddress: z
      .string(),
    billingInvoicingForm: z
      .string(),
    taxNumber: z
      .string()
      .max(150, 'Must be less then 150 characters'),
    bank: z
      .string()
      .min(1, 'Bank is required')
      .max(150, 'Must be less then 150 characters'),
    accountCode: z
      .string()
      .max(14, 'SWIFT code must be less then 15 characters'),
    accountIban: z
      .string()
      .max(49, 'IBAN account number must be less then 50 characters'),
  });

  type CreativeBillingData = z.infer<typeof creativeBillingSchema>;


  const defaultValues = {
    billingName: creativeData.billingName,
    birthDate: creativeData.birthDate ? moment(creativeData.birthDate).startOf('d').utc(true).toDate(): undefined,
    billingAddress: creativeData.billingAddress,
    billingAddressObj: creativeData.billingAddressObj || {},
    billingInvoicingForm:
      creativeData.billingInvoicingForm === 'TBD' ? '0%' : creativeData.billingInvoicingForm,
    taxNumber: creativeData.taxNumber,
    bank: creativeData.bank,
    accountNumber: creativeData.accountNumber,
    accountIban: creativeData.accountIban,
    accountCode: creativeData.accountCode,
    billingLanguage: creativeData.billingLanguage,
  };

  const {
    handleSubmit,
    register,
    formState: {isSubmitting, isDirty, errors},
    reset,
    getValues,
    watch,
    control,
    setValue,
  } = useForm({
    defaultValues,
    resolver: zodResolver(creativeBillingSchema),
  });

  const [
    {error: updateCreativeError},
    updateCreative,
  ] = useUpdateCreative(creativeId as string);

  const billingLanguage = getValues('billingLanguage');

  useEffect(
    () => {
      if (updateCreativeError) {
        toast({
          title: 'Update was successfull',
          status: 'error',
          duration: 3000,
        });
      }
    },
    [updateCreativeError],
  );

  const handleSaveClick = async (data: CreativeBillingData) => {
    await updateCreative({data});

    setIsFormDirty(false);
    toast({
      title: 'Update was successfull',
      status: 'success',
      duration: 3000,
    });
  };

  useEffect(
    () => {
      setShouldReset(!isDirty);
      setIsFormDirty(isDirty);
    },
    [isDirty],
  );

  useEffect(
    () => {
      if (shouldReset) reset(defaultValues);
    },
    [shouldReset],
  );

  const mergeBillingAddress = () => {
    const billingAddressObj = getValues('billingAddressObj');
    const {country, zip, street, city} = billingAddressObj;
    return `${street}, ${city}, ${zip} ${country}`;
  };

  const handlePreSaveBillingAddress = () => {
    setValue('billingAddressObj.zip', billingAddressObj.zip);
    setValue('billingAddressObj.street', billingAddressObj.street);
    setValue('billingAddressObj.city', billingAddressObj.city);
    setValue('billingAddress', mergeBillingAddress());
    onClose();
  };

  const watchBillingCountry = watch('billingAddressObj.country');

  return <Box mt='50px'>
    <form onSubmit={handleSubmit(handleSaveClick)}>
      <Flex
        justifyContent='space-around'
        alignItems={{base: 'center', md: 'start'}}
        direction={{base: 'column', md: 'row'}}
      >
        <Flex direction='column'>
          <CustomField id='billingName' label={'Billing name'} register={register} errors={errors}/>
          <Box mb='10px'>
            <FormLabel>{'Date of birth'}</FormLabel>
            <Controller
              name={'birthDate'}
              control={control}
              render={() => (
                <Box
                  as={DateTimePicker}
                  clearIcon={null}
                  calendarIcon={null}
                  disableClock
                  showLeadingZeros
                  format="dd-MM-yyyy"
                  minDate={moment('1951.01.01').toDate()}
                  maxDate={moment().toDate()}
                  value={watch('birthDate')}
                  onChange={(newValue: Date | null) => {
                    if (newValue) {
                      setValue('birthDate', moment(newValue).startOf('d').utc(true).toDate(), {shouldDirty: true});
                    }
                  }}
                  sx={{
                    ...datePickerStyles,
                    'height': '40px',
                    'width': '500px',
                    'border-radius': '15px',
                    'font-size': '14px',
                    'padding-left': '20px',
                    'margin-bottom': '0px',
                    'border': '1px solid rgb(224, 229, 242)',
                    '@media (max-width: 768px)': {
                      width: '100%',
                    },
                  }}
                />
              )}
            />
            <Text color='red' fontSize='14px' mt='0px'>{errors.birthDate?.message}</Text>
          </Box>

          <FormControl mb={4} w={{base: '90vw', md: '500px'}}>
            <FormLabel>Country</FormLabel>
            <Select
              {...register('billingAddressObj.country')}
              w={{base: '90vw', md: '500px'}}
              h='40px'
              bg='white'
              borderRadius='15px'
              value={watchBillingCountry}
            >
              {
                countryList.map(
                  (country: CountryItem) => <option key={country.value} value={country.value}>
                    {country.label}
                  </option>,
                )
              }
            </Select>
          </FormControl>

          <Box>
            <FormLabel>Billing address</FormLabel>
            <Flex>
              <Text {...CustomFieldStyle} w={{base: '100%', md: '440px'}} pt='8px'>{watch('billingAddress')}</Text>
              <Button ml='5px' onClick={() => onOpen()}>
                <MdModeEdit size={20}/>
              </Button>
            </Flex>
          </Box>

          <FormControl alignItems='center' m='5px 0'>
            <FormLabel>Billing language</FormLabel>
            <Select
              {...register('billingLanguage')}
              display='flex'
              alignItems='center'
              bg='white'
              borderRadius='15px'
            >
              <option value='en'>English</option>
              <option value='hu'>Hungarian</option>
            </Select>
          </FormControl>

          <FormControl display='flex' alignItems='center' m='15px 0' id='autoBilling'>
            <FormLabel>Automatic billing:</FormLabel>
            <Box mb='10px'>
              <Text>
                {billingLanguage === 'hu' ? 'Billingo integration' : 'AutoBilling'}
                &nbsp;
                {creativeData.autoBilling?.active ? <CheckIcon/> : <CloseIcon/>}
              </Text>
            </Box>
          </FormControl>
        </Flex>

        <Flex direction='column'>
          <CustomField id='billingInvoicingForm' label={'Billing invoicing form'} register={register} errors={errors}/>
          <CustomField id='taxNumber' label={'Tax number'} register={register} errors={errors}/>
          <CustomField id='bank' label={'Bank'} register={register} errors={errors}/>
          <CustomField id='accountIban' label={'Account Number (IBAN)'} register={register} errors={errors}/>
          <CustomField id='accountNumber' label={'Account Number'} register={register} errors={errors}/>
          <CustomField id='accountCode' label={'SWIFT/BIC'} register={register} errors={errors}/>
        </Flex>
      </Flex>

      <Flex justifyContent='center' m='50px'>
        <Button isLoading={isSubmitting} type='submit' variant='photonDark' w='200px'>
          Save
        </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit billing address</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <FormLabel>{'Zip code'}</FormLabel>
              <Input variant='main' onChange={(e)=>setBillingAddressObj({...billingAddressObj, zip: e.target.value})} value={billingAddressObj.zip}/>
            </Box>
            <Box>
              <FormLabel>{'City'}</FormLabel>
              <Input variant='main' onChange={(e)=>setBillingAddressObj({...billingAddressObj, city: e.target.value})} value={billingAddressObj.city}/>
            </Box>
            <Box>
              <FormLabel>{'Street'}</FormLabel>
              <Input variant='main' onChange={(e)=>setBillingAddressObj({...billingAddressObj, street: e.target.value})} value={billingAddressObj.street}/>
            </Box>
            <ModalFooter display='flex' justifyContent='center'>
              <Button
                variant="photonDark"
                onClick={handlePreSaveBillingAddress}
                isDisabled={Object.values(billingAddressObj).some((v)=> !v)}
              >
                OK
              </Button>
            </ModalFooter>
          </ModalBody>

        </ModalContent>
      </Modal>
    </form>
  </Box>;
}
