import moment from 'moment';

function calculateDeliveryDeadlines({
  projectData,
  shootingTime,
  timeDifferenceInHours,
}: {
  // eslint-disable-next-line
  projectData: any; // TODO specify type
  shootingTime: string;
  timeDifferenceInHours: number;
}) {
  const {deliveryTime, photographerDeliveryTime, projectDrivers}
    = projectData;

  const photographerDeliveryDeadline = moment(shootingTime);
  const deliveryDeadline = moment(shootingTime);

  deliveryDeadline.add(
    deliveryTime.amount,
    deliveryTime.unit,
  );

  photographerDeliveryDeadline.add(
    photographerDeliveryTime?.amount || deliveryTime.amount,
    photographerDeliveryTime?.unit || deliveryTime.unit,
  );

  if (projectDrivers.includes('delivery_on_workday')) {
    if (deliveryDeadline.isoWeekday() > 5) {
      deliveryDeadline.add(1, 'w').isoWeekday(1);
    }
  }

  if (projectDrivers.includes('delivery_at_time')) {
    if (deliveryTime.atHour >= 0 && deliveryTime.atHour <= 23) {
      const adjustedHour
        = deliveryTime.atHour - Math.floor(timeDifferenceInHours);
      deliveryDeadline.hour(adjustedHour).minutes(0).seconds(0).milliseconds(0);
    }

    if (
      photographerDeliveryTime.atHour >= 0
      && photographerDeliveryTime.atHour <= 23
    ) {
      const adjustedHour
        = photographerDeliveryTime.atHour - Math.floor(timeDifferenceInHours);
      photographerDeliveryDeadline
        .hour(adjustedHour)
        .minutes(0)
        .seconds(0)
        .milliseconds(0);
    }
  }

  return {
    deliveryDeadline,
    photographerDeliveryDeadline,
  };
}

export default calculateDeliveryDeadlines;
